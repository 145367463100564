import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './NotificationBadge.module.css';

export interface Props {
	count: number;
	position?: 'absolute' | 'relative';
}

export function NotificationBadge({count, position = 'absolute'}: Props) {
	const classes = classNames(
		style.NotificationBadge,
		CSSVariants(style, 'NotificationBadge', position),
	);

	return <span className={classes}>{count}</span>;
}
