import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery} from '@halp/api/graphql';
import {useChannel} from '@halp/api/channels';
import {useUser} from '../Users';
import {UserNotificationsDocument} from './UserNotifications.query';
import {DismissNotificationDocument} from './DismissNotification.mutation';
import {DismissAllNotificationsDocument} from './DismissAllNotifications.mutation';
import type {Notification} from './types';
import type {ReactNode} from 'react';

export const NotificationContext = createContext<{
	notifications: Notification[];
	dismiss: (id: string) => void;
	dismissAll: () => void;
} | null>(null);

export function NotificationProvider({children}: {children: ReactNode}) {
	const {user} = useUser();

	const {data, status} = useQuery(UserNotificationsDocument, {
		variables: {userId: user?.id || ''},
		enabled: !!user,
	});

	const [dismissNotification] = useMutation(DismissNotificationDocument, {
		invalidateQueries: [
			{
				document: UserNotificationsDocument,
				variables: {
					userId: user?.id,
				},
			},
		],
	});

	const [dismissAllNotifications] = useMutation(
		DismissAllNotificationsDocument,
		{
			invalidateQueries: [
				{
					document: UserNotificationsDocument,
					variables: {
						userId: user?.id,
					},
				},
			],
		},
	);

	const [notifications, setNotifications] = useState<Notification[]>([]);

	useEffect(() => {
		if (status === 'success' && data) {
			setNotifications(
				data.listUserNotifications.map((notification) => ({
					id: notification.id,
					message: notification.message,
				})),
			);
		}
	}, [data, status]);

	const notificationChannel = useChannel(`user_notifications:${user?.id}`);

	useEffect(() => {
		if (!notificationChannel) return;

		const channelRef = notificationChannel.on('notification', (response) => {
			setNotifications((prev) => [
				...prev,
				{id: response.id, message: response.message},
			]);
		});

		return () => {
			notificationChannel.off('notification', channelRef);
		};
	}, [notificationChannel]);

	const providerValue = useMemo(() => {
		return {
			notifications: notifications,
			dismiss: (id: string) => dismissNotification({id: id}),
			dismissAll: () => dismissAllNotifications({userId: user?.id as string}),
		};
	}, [notifications, dismissNotification, dismissAllNotifications, user]);

	return (
		<NotificationContext.Provider value={providerValue}>
			{children}
		</NotificationContext.Provider>
	);
}

export function useNotifications() {
	const notifications = useContext(NotificationContext);
	if (!notifications) {
		throw new Error(
			'[Notifications]: Called outside of the Notification provider',
		);
	}
	return notifications;
}
